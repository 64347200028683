import React from "react"
import { Link } from "gatsby"

import Seo from "../components/seo"

export default function NotFound() {
  const heroTitle = "Page not found"
  return (
    <>
      <Seo title={heroTitle} />
      <section class="c-section c-section-sm">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-12 col-md-12">
              <div class="innerpage--content innerpage--content-sm m-0 p-0 page--404">
                <div class="row justify-content-center">
                  <div class="col-12 col-xl-7 col-lg-8">
                    <div class="error-text-box error-text-box-sm">
                      <svg viewBox="0 0 600 200">
                        <symbol id="s-text">
                          <text text-anchor="middle" x="50%" y="50%" dy=".35em">404!</text>
                        </symbol>
                        <use className="text" xlink:href="#s-text">
                          <symbol id="s-text">
                            <text text-anchor="middle" x="50%" y="50%" dy=".35em">404!</text>
                          </symbol>
                        </use>
                        <use className="text" xlink:href="#s-text">
                          <symbol id="s-text">
                            <text text-anchor="middle" x="50%" y="50%" dy=".35em">404!</text>
                          </symbol>
                        </use>
                        <use className="text" xlink:href="#s-text">
                          <symbol id="s-text">
                            <text text-anchor="middle" x="50%" y="50%" dy=".35em">404!</text>
                          </symbol>
                        </use>
                        <use className="text" xlink:href="#s-text">
                          <symbol id="s-text">
                            <text text-anchor="middle" x="50%" y="50%" dy=".35em">404!</text>
                          </symbol>
                        </use>
                        <use className="text" xlink:href="#s-text">
                          <symbol id="s-text">
                            <text text-anchor="middle" x="50%" y="50%" dy=".35em">404!</text>
                          </symbol>
                        </use>
                        </svg>
                    </div>
                    <div class="text-center">
                        <h3 class="mt-0 mb-2">Whoops! Page not found </h3>
                        <p class="text-muted mb-3">It's looking like you may have taken a wrong turn. Don't worry...
                            it happens to the best of us. You might want to check your internet connection.
                            Here's a little tip that might help you get back on track.</p>

                        <Link href="/" class="c-button c-button--blue c-button-radius c-button-inline"><i className="fe-home mr-1"></i>Back to Home</Link>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
